@font-face {
  font-family: "HelveticaNowforMonksMedium";
  src: local("HelveticaNowforMonksMedium"),
    url("./fonts/HelveticaNowforMonksMedium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "HelveticaNowforMonksBold";
  src: local("HelveticaNowforMonksBold"),
    url("./fonts/HelveticaNowforMonksBold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "HelveticaNowforMonksExtraBold";
  src: local("HelveticaNowforMonksExtraBold"),
    url("./fonts/HelveticaNowforMonksExtraBold.otf") format("opentype");
  font-weight: 800;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.uppercase {
  text-transform: uppercase;
  font-weight: bold !important; 
}

option {
  color: #fff;
  background-color: #333 !important;
}